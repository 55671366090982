// src/components/CourseCard.js
import React from 'react';
import { Link } from 'react-router-dom';
import './CourseCard.css';

const CourseCard = ({ course }) => {
  // Fallback for missing bgColor
  // const backgroundColor = course.bgColor || '#fff';

  return (

    <Link to={`/course/${course.id}`} className="course-card" >
      <div className="course-card-image">
        <img src={course.image} alt={course.title} />
      </div>
      <div className="course-card-content">
        <p className="course-category">{course.category}</p>
        <h2 className="course-title">{course.title}</h2>
        <div className="course-rating">
          {[...Array(5)].map((_, i) => (
            <span key={i} className="star">★</span>
          ))}
        </div>
        <p className="course-rating-text">Highly Rated</p>
        <p className="course-duration">{course.duration}+ days</p>
      </div>
    </Link>
    
  );
};

export default CourseCard;



