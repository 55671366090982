import React, { useEffect } from "react";
import CourseImg from "../Assests/course.png";
import DotBg from "..//Assests/About/pentip-splash.jpg";
import MissionImg from "../Assests/About/focus_mission.png";
import Testimonial from "../CourseCards/CourseList/Testimonial";

const AboutUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page
  }, []);

  return (
  <div className="min-h-screen">
    {/* Full-width background section */}
    <div className="w-full bg-orange-100">
      <div className="max-w-6xl mx-auto flex flex-col md:flex-row items-center justify-between px-6">
        <h1 className="text-4xl md:text-4xl font-bold text-center md:text-left mb-4 md:mb-0 uppercase">
          About Us
        </h1>
        <img
          src={CourseImg}
          alt="Courses"
          className="w-48 h-auto md:w-[500px] object-contain"
        />
      </div>
    </div>

    {/* New content section */}
    <div className="max-w-6xl mx-auto flex flex-col md:flex-row items-center justify-between px-6 py-8">
      {/* Dot Background on the left */}
      <div className="md:w-1/2 flex justify-center">
        <img src={DotBg} alt="Dot Background" className="w-full h-auto" />
      </div>
      {/* Text content on the right */}
      <div className="md:w-1/2 text-center md:text-left ml-6">
        <h2 className="text-2xl md:text-3xl font-semibold mb-4">Who we are?</h2>
        <p className="md:text-lg leading-relaxed mb-4">
          Pentip started up in 2013, and we’ve been on an epic ride ever since.
          From our beginnings as a training company, we’ve tried to stay true to
          our core beliefs and to deliver an exceptional learning experience and
          job support for our customers and learners.
        </p>
        <p className="md:text-lg leading-relaxed mb-4">
          We are a pure play development, test automation, and DevOps training
          and consulting factory, and we love what we do. It’s who we are. As an
          agile team working side by side, we aspire for the designed life: a
          place where work, life, and inspiration are all equal and integrated
          organically.
        </p>
        {/* Establishment Image aligned to the left */}
        <div className="flex items-center space-x-9">
          <div className="relative flex items-center justify-center w-32 h-32 border-4 border-orange-500 rounded-ss-3xl rounded-ee-3xl rounded-se-3xl">
            <span className="text-7xl font-extrabold text-gray-800 absolute left-[10px] mb-4 tracking-tighter">
              10+
            </span>
          </div>
          <div className="flex flex-col text-left mb-2">
            <span className="text-lg font-semibold">Years</span>
            <span className="text-lg font-semibold">Of</span>
            <span className="text-lg font-semibold">Establishment</span>
          </div>
        </div>
      </div>
    </div>

    <div className="w-full bg-orange-600">
      <div class="max-w-6xl mx-auto flex flex-col md:flex-row items-center justify-between px-6 py-10 md:py-20">
        <div class="md:w-1/2 text-center md:text-left md:ml-6 text-white">
          <h2 class="text-4xl md:text-5xl font-bold mb-8 md:mb-16">
            Our Mission
          </h2>
          <p class="md:text-lg leading-relaxed mb-4 font-semibold">
            To make the training more transparent at of best quality and less
            costlier. Today we provide the most actionable training in the
            industry. We want to make this training and consulting available to
            as many people as possible (not just the top 5%).
          </p>
          <p class="text-lg leading-relaxed mb-4 font-semibold">
            At Pentip, our goal is to make high-quality, actionable training
            accessible and affordable for everyone—not just the top 5%. We are
            committed to offering transparent, value-driven education that
            empowers individuals to achieve their best. By providing
            industry-leading training and consulting, we aim to bridge the gap
            and create opportunities for all.
          </p>
        </div>
        <div class="md:w-1/2 flex justify-center">
          <img
            src={MissionImg}
            alt="Dot Background"
            class="w-full h-[450px] object-contain"
          />
        </div>
      </div>
    </div>

    <div className="max-w-6xl mx-auto px-6 py-4 md:py-8 my-8 sm:row">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 ">
        <div className="flex flex-col items-center">
          <div className="w-24 md:w-48 h-1 bg-black mb-4"></div>
          <p className="text-3xl md:text-4xl font-bold mb-2">5000+</p>
          <p className="font-bold text-lg md:text-xl text-center">
            Happy Learners
          </p>
        </div>
        <div className="flex flex-col items-center">
          <div className="w-24 md:w-48 h-1 bg-black mb-4"></div>
          <p className="text-3xl md:text-4xl font-bold mb-2">250+</p>
          <p className="font-bold text-lg md:text-xl text-center">Companies</p>
        </div>
        <div className="flex flex-col items-center">
          <div className="w-24 md:w-48 h-1 bg-black mb-4"></div>
          <p className="text-3xl md:text-4xl font-bold mb-2">50+</p>
          <p className="font-bold text-lg md:text-xl text-center">Trainers</p>
        </div>
        <div className="flex flex-col items-center">
          <div className="w-24 md:w-48 h-1 bg-black mb-4"></div>
          <p className="text-3xl md:text-4xl font-bold mb-2">15+</p>
          <p className="font-bold text-lg md:text-xl text-center">Courses</p>
        </div>
      </div>
    </div>

    <div className="bg-gray-800 py-6">
      <Testimonial />
    </div>
  </div>
);
}
export default AboutUs;


