import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { FaStar } from "react-icons/fa";
import unlockfinal from "../../Assests/CourseDetails/UNLOCK.webp";
import { courses } from "./CourseItem";
import { FaTools, FaCalendarAlt } from "react-icons/fa";
import istqb from "../../Assests/courses/ISTQB.webp";
import "./CertificationDetails.css";
import cste from "../../Assests/courses/Certifications/cste.webp";
import ctfl from "../../Assests/courses/Certifications/ctfl.webp";
import csqa from "../../Assests/courses/Certifications/csqa.webp";
import cqa from "../../Assests/courses/Certifications/cqa.webp";
import agile from "../../Assests/courses/Certifications/agile.webp";
import automationtesting from "../../Assests/courses/Certifications/automationtesting.webp";
import certificationcourses from "../../Assests/courses/certificationcourses.webp";

const CourseDetails = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page
  }, []);

  const [errors, setErrors] = useState({});
  const [openFAQ, setOpenFAQ] = useState(null);
  const [activeIndex, setActiveIndex] = useState({ left: 0, right: 0 });

  const handleClick = (index, side) => {
    setActiveIndex((prev) => ({
      ...prev,
      [side]: prev[side] === index ? null : index,
    }));
  };

  const { id } = useParams();
  const course = courses.find((course) => course.id === parseInt(id));
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    course: course.title, // Default to the current course title
    message: "",
  });
  if (!course) {
    return <div>Course not found</div>;
  }
  const rating = 5;

  const handleToggle = (id) => {
    setOpenFAQ(openFAQ === id ? null : id);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: "" }); // Clear error on change
  };

  const validate = () => {
    const newErrors = {};
    if (!formData.name) newErrors.name = "Name is required";
    if (!formData.email) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Email address is invalid";
    }
    if (!formData.phone) {
      newErrors.phone = "Phone number is required";
    } else if (!/^\d{10}$/.test(formData.phone)) {
      newErrors.phone = "Phone number must be 10 digits";
    }
    if (!formData.message) newErrors.message = "Message cannot be empty";

    return newErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const validationErrors = validate();
    if (Object.keys(validationErrors).length) {
      setErrors(validationErrors);
      return;
    }

    const whatsappMessage = `Name: ${formData.name}%0AEmail: ${formData.email}%0APhone: ${formData.phone}%0ACourse: ${formData.course}%0AMessage: ${formData.message}`;

    window.open(
      `https://wa.me/+919014994800?text=${whatsappMessage}`,
      "_blank"
    );

    // Reset form after submission
    setFormData({
      name: "",
      email: "",
      phone: "",
      course: course.title,
      message: "",
    });
    setErrors({});
  };

  if (course.id <= 12) {
    return (
      <div className="min-h-screen">
        <div className="w-full bg-orange-100 py-6 md:py-8">
          <div className="max-w-6xl mx-auto flex flex-col md:flex-row items-center justify-between px-6">
            {/* Rating Section */}
            <div>
              <div className="flex items-center justify-center md:justify-start mb-4">
                {[...Array(rating)].map((_, index) => (
                  <FaStar key={index} className="text-yellow-500 w-6 h-6" />
                ))}
              </div>
              <span className="text-lg font-semibold text-gray-700">
                Highly Rated
              </span>
              <h1 className="text-4xl font-bold">{course.title}</h1>
            </div>
            <img
              src={course.image}
              alt={course.title}
              className="w-auto h-64 object-contain"
            />
          </div>
        </div>

        <div className="w-full bg-white py-6 md:py-12">
          <div className="max-w-6xl mx-auto px-6">
            <div className="flex flex-col md:flex-row items-center md:items-start">
              {/* Image */}
              <div className="flex-shrink-0 mb-6 md:mb-0 mt-16">
                <img
                  src={unlockfinal}
                  alt="Azure DevOps"
                  className="w-auto h-full object-contain rounded-3xl"
                />
              </div>
              {/* Text */}
              <div className="md:w-1/2 md:pl-6">
                <h1 className="text-3xl font-bold">
                  Unlock the knowledge of{" "}
                  <span className="text-primary">{course.title}.</span>
                </h1>
                <p className="text-[16px] text-gray-800 mb-4 mt-2 text-center text-justify">
                  {course.description1}
                </p>
                <p className="text-[16px] text-gray-800 text-center text-justify">
                  {course.description2}
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Features Section */}
        <div className="w-full py-12">
          <div className="max-w-6xl mx-auto px-6">
            <h2 className="text-3xl font-semibold text-center mb-8">
              {course.title}
              <span className="text-primary"> Training Features</span>
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              {/* Feature Card */}
              <div className="bg-white p-6 rounded-lg shadow-md flex flex-col items-center">
                {/* <img
                src={Collaboration}
                alt="Collaboration"
                className="w-20 h-20 mb-4"
              /> */}
                <h3 className="text-xl font-semibold mb-4">
                  {course.Features[0].f1h}
                </h3>
                <p className="text-gray-700 text-center">
                  {course.Features[0].f1d}
                </p>
              </div>
              {/* Feature Card */}
              <div className="bg-white p-6 rounded-lg shadow-md flex flex-col items-center">
                {/* <img
                src={Automation}
                alt="Automation"
                className="w-20 h-20 mb-4"
              /> */}
                <h3 className="text-xl font-semibold mb-4">
                  {course.Features[0].f2h}
                </h3>
                <p className="text-gray-700 text-center">
                  {course.Features[0].f2d}
                </p>
              </div>
              {/* Feature Card */}
              <div className="bg-white p-6 rounded-lg shadow-md flex flex-col items-center">
                {/* <img
                src={Integration}
                alt="Continuous Integration (CI)"
                className="w-20 h-20 mb-4"
              /> */}
                <h3 className="text-xl font-semibold mb-4">
                  {course.Features[0].f3h}
                </h3>
                <p className="text-gray-700 text-center">
                  {course.Features[0].f3d}
                </p>
              </div>
              {/* Feature Card */}
              <div className="bg-white p-6 rounded-lg shadow-md flex flex-col items-center">
                {/* <img
                src={Deployment}
                alt="Continuous Deployment (CD)"
                className="w-20 h-20 mb-4"
              /> */}
                <h3 className="text-xl font-semibold mb-4">
                  {course.Features[0].f4h}
                </h3>
                <p className="text-gray-700 text-center">
                  {course.Features[0].f4d}
                </p>
              </div>
              {/* Feature Card */}
              <div className="bg-white p-6 rounded-lg shadow-md flex flex-col items-center">
                {/* <img
                src={Monitor}
                alt="Infrastructure as Code (IaC)"
                className="w-20 h-20 mb-4"
              /> */}
                <h3 className="text-xl font-semibold mb-4">
                  {course.Features[0].f5h}
                </h3>
                <p className="text-gray-700 text-center">
                  {course.Features[0].f5d}
                </p>
              </div>
              {/* Feature Card */}
              <div className="bg-white p-6 rounded-lg shadow-md flex flex-col items-center">
                {/* <img
                src={Iac}
                alt="Monitoring and Logging"
                className="w-20 h-20 mb-4"
              /> */}
                <h3 className="text-xl font-semibold mb-4">
                  {course.Features[0].f6h}
                </h3>
                <p className="text-gray-700 text-center">
                  {course.Features[0].f6d}
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Importance of Azure DevOps Test Automation Training Section */}
        <div className="w-full py-12">
          <div className="max-w-6xl mx-auto px-6">
            <div className="flex flex-col md:flex-row items-center md:items-start">
              {/* Text */}
              <div className="mb-6 md:mb-0">
                <h2 className="text-3xl font-bold mb-4">
                  How important is the{" "}
                  <span className="text-[#FF8C00]">{course.title}</span>?
                </h2>
                <p className="text-md text-gray-800 mb-4 text-center text-justify">
                  {course.importance1}
                </p>
                <p className="text-md text-gray-800 text-center text-justify">
                  {course.importance2}
                </p>
              </div>
              {/* Image */}
              <div className="flex-shrink-0 flex justify-center items-center sm:ml-0 md:ml-10">
  <img
    src={course.impimg}
    alt="Importance of Azure DevOps Test Automation"
    className="w-full h-full object-contain rounded-3xl md:w-auto md:h-auto"
  />
</div>


            </div>
            <p className="text-md text-gray-800 text-center text-justify">
              {course.importance3}
            </p>
          </div>
        </div>

        {/* Trainer Bio Section */}
        <div className="w-full mb-12">
          <div className="max-w-6xl mx-auto px-6">
            <p className="text-2xl md:text-3xl font-semibold py-6 text-center md:text-left">
              Short Bio about our{" "}
              <span className="text-[#FF8C00]">{course.title}</span>
            </p>
            <div className="bg-gray-100 p-6 rounded-lg trainer">
              <div className="flex flex-col md:flex-row items-center">
                {/* Image */}
                <div className="flex-shrink-0 mb-6 md:mb-0 md:mr-6 flex justify-center">
                  <img
                    src={course.TrainerImg}
                    alt={course.trainer1}
                    className="w-24 h-24 object-cover rounded-full border-8 border-white"
                  />
                </div>
                {/* Bio Text */}
                <div className="text-center md:text-left">
                  <h2 className="text-xl md:text-2xl font-semibold mb-2">
                    {course.trainer1}
                  </h2>
                  <h3 className="text-lg md:text-xl text-[#FF8C00] font-semibold mb-4">
                    {course.trainer1tag}
                  </h3>
                </div>
              </div>
              <p className="text-sm md:text-md text-gray-800 mb-4">
                {course.trainerdescription}
              </p>

              <div className="flex flex-col md:flex-row justify-center md:justify-between text-sm md:text-md">
                <div className="flex items-center mb-4 md:mb-0">
                  <FaTools className="text-[#FF8C00] w-5 h-5 mr-2" />
                  <span className="text-md text-gray-800">
                    <strong>{course.title} EXPERT</strong>
                  </span>
                </div>
                <div className="flex items-center">
                  <FaCalendarAlt className="text-[#FF8C00] w-5 h-5 mr-2" />
                  <span className="text-md text-gray-800">
                    <strong>{course.trainer1exp}+ Years of Experience</strong>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Best Way to Learn Azure DevOps Test Automation Section */}
        <div className="w-full bg-[#FFE5B4] py-6 md:py-12">
          <div className="max-w-6xl mx-auto px-6 flex flex-col md:flex-row items-center">
            {/* Text */}
            <div className="md:w-full mb-6 md:mb-0 text-center text-justify">
              <h2 className="text-2xl font-bold mb-4">
                Best way to learn{" "}
                <span className="text-[#FF8C00]">{course.title}</span>
              </h2>
              <p className="text-md text-gray-800 mb-4">{course.learn1} </p>
              <p className="text-md text-gray-800 mb-4">{course.learn2} </p>
              {/* <div className='flex justify-between items-center'>
                            <p className="text-lg font-bold text-gray-800">
                                Launching Date: Sep 07 2024
                            </p>
                            <button className="bg-[#FF8C00] text-white py-2 px-6 rounded-2xl font-semibold">Enroll Now</button>
                        </div> */}
            </div>
            {/* Image */}
            {/* <div className="w-1/2 flex justify-center">
                        <img src={LearningImg} alt="Learning Azure DevOps" className="w-full max-w-md h-auto object-cover rounded-md" />
                    </div> */}
          </div>
        </div>

        {/* FAQs Section */}
        <div className="max-w-6xl mx-auto p-6 bg-white mt-12">
          <h2 className="text-5xl font-bold mb-12">FAQs</h2>

          {course.faqs.map((faq) => (
            <div key={faq.id} className="mb-4">
              <button
                className="w-full text-left bg-[#FF8C00] text-white font-bold px-4 py-4 rounded-lg focus:outline-none hover:bg-[#FF8C00]"
                onClick={() => handleToggle(faq.id)}
              >
                {faq.question}
              </button>
              <div
                className={`faq-content transition-max-height bg-gray-100 duration-300 ease-out overflow-hidden ${
                  openFAQ === faq.id ? "max-h-screen p-4" : "max-h-0"
                }`}
              >
                <p>{faq.answer}</p>
              </div>
            </div>
          ))}
        </div>

        {/* Enrollment Section */}
        <div className="w-full py-12 bg-[#FFE5B4]">
          <div className="max-w-6xl mx-auto py-6 px-6 rounded-2xl mb-4">
            <h2 className="text-2xl md:text-3xl font-bold mb-6 text-center md:text-left">
              Enroll Now and Kick Start Your {course.title} Journey
            </h2>

            <div className="p-6">
              <form
                className="grid grid-cols-1 md:grid-cols-2 gap-6"
                onSubmit={handleSubmit}
              >
                <div className="col-span-2 sm:col-span-1">
                  <label
                    htmlFor="name"
                    className="block text-2xl text-gray-700"
                  >
                    Name<span className="text-red-700 ml-1">*</span>
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    className={`mt-1 block w-full px-3 py-4 border ${
                      errors.name ? "border-red-500" : "border-gray-300"
                    } rounded-md shadow-sm focus:outline-none focus:ring-[#FF8C00] focus:border-[#FF8C00] text-sm md:text-md`}
                  />
                  {errors.name && (
                    <p className="text-red-500 text-sm">{errors.name}</p>
                  )}
                </div>
                <div className="col-span-2 sm:col-span-1">
                  <label
                    htmlFor="email"
                    className="block text-2xl text-gray-700"
                  >
                    Email<span className="text-red-700 ml-1">*</span>
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    className={`mt-1 block w-full px-3 py-4 border ${
                      errors.email ? "border-red-500" : "border-gray-300"
                    } rounded-md shadow-sm focus:outline-none focus:ring-[#FF8C00] focus:border-[#FF8C00] sm:text-md`}
                  />
                  {errors.email && (
                    <p className="text-red-500 text-sm">{errors.email}</p>
                  )}
                </div>
                <div className="col-span-2 sm:col-span-1">
                  <label
                    htmlFor="phone"
                    className="block text-2xl text-gray-700"
                  >
                    Phone Number<span className="text-red-700 ml-1">*</span>
                  </label>
                  <input
                    type="tel"
                    id="phone"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    className={`mt-1 block w-full px-3 py-4 border ${
                      errors.phone ? "border-red-500" : "border-gray-300"
                    } rounded-md shadow-sm focus:outline-none focus:ring-[#FF8C00] focus:border-[#FF8C00] sm:text-md`}
                  />
                  {errors.phone && (
                    <p className="text-red-500 text-sm">{errors.phone}</p>
                  )}
                </div>
                <div className="col-span-2 sm:col-span-1">
                  <label
                    htmlFor="course"
                    className="block text-2xl text-gray-700"
                  >
                    Course<span className="text-red-700 ml-1">*</span>
                  </label>
                  <select
                    id="course"
                    name="course"
                    value={formData.course}
                    onChange={handleChange}
                    className="mt-1 block w-full px-3 py-4 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-[#FF8C00] focus:border-[#FF8C00] sm:text-md"
                  >
                    <option value="">{course.title}</option>
                    {/* Add more course options here as needed */}
                    <option>Software Testing Selenium core java</option>
                    <option>API Testing (REST Azure)</option>
                    <option>DevOps</option>
                    <option>React js</option>
                    <option>SAP</option>
                  </select>
                </div>
                <div className="col-span-2">
                  <label
                    htmlFor="message"
                    className="block text-2xl text-gray-700"
                  >
                    Message<span className="text-red-700 ml-1">*</span>
                  </label>
                  <textarea
                    id="message"
                    name="message"
                    rows="4"
                    value={formData.message}
                    onChange={handleChange}
                    className={`mt-1 block w-full px-3 py-4 border ${
                      errors.message ? "border-red-500" : "border-gray-300"
                    } rounded-md shadow-sm focus:outline-none focus:ring-[#FF8C00] focus:border-[#FF8C00] sm:text-sm`}
                  ></textarea>
                  {errors.message && (
                    <p className="text-red-500 text-sm">{errors.message}</p>
                  )}
                </div>
                <div className="col-span-2 flex justify-center items-center mt-4">
                  <button
                    type="submit"
                    className="bg-[#FF8C00] text-white px-4 py-2 rounded-md shadow-sm hover:bg-[#FF8C00] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#FF8C00]"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }

  // If course ID is 13 or higher, apply a different format
  else {
    return (
      <div key={course.id} className="w-full bg-gray-100 py-6 md:py-12">
        <div>
          <div className="w-full bg-orange-100 py-6 md:py-8">
            <div className="max-w-6xl mx-auto flex flex-col md:flex-row items-center justify-between px-6">
              {/* Rating Section */}
              <div>
                <div className="flex items-center justify-center md:justify-start mb-4">
                  {[...Array(4)].map((_, index) => (
                    <FaStar key={index} className="text-yellow-500 w-6 h-6" />
                  ))}
                </div>
                <span className="text-lg font-semibold text-gray-700">
                  Highly Rated
                </span>
                <h1 className="text-4xl font-bold">Certification Courses</h1>
              </div>
              <img
                src={certificationcourses}
                alt="certificationcourses"
                className="w-auto h-64 object-contain"
              />
            </div>
          </div>

          <div className="certificate-cards-container">

<div className="istqb-section ">
  <div className="istqb-content">
    <img
      src={istqb}
      alt="ISTQB Certification"
      className="istqb-image "
    />
    <div className="istqb-text">
      <h2 className="istqb-heading">ISTQB Certification</h2>
      <p className="istqb-paragraph">
        The{" "}
        <strong>
          ISTQB (International Software Testing Qualifications Board)
        </strong>{" "}
        Certification is a globally recognized certification for
        software testers. It aims to standardize the qualifications and
        knowledge in the software testing field, providing a structured
        path for career growth.
      </p>
    </div>
  </div>
</div>
<div className="istqb-section ">
  <div className="istqb-content">
    <img src={cste} alt="ISTQB Certification" className="istqb-image" />
    <div className="istqb-text">
      <h2 className="istqb-heading">CSTE Certification</h2>
      <p className="istqb-paragraph">
        The<strong>CSTE (Certified Software Test Engineer)</strong>{" "}
        Certification is a recognized credential for software testers.
        It aims to validate and standardize knowledge and expertise in
        the field of software testing, providing a structured path for
        career advancement.
      </p>
     
    </div>
  </div>
</div>
<div className="istqb-section ">
  <div className="istqb-content">
    <img src={ctfl} alt="ISTQB Certification" className="istqb-image" />
    <div className="istqb-text">
      <h2 className="istqb-heading">CTFL Certification</h2>
      <p className="istqb-paragraph">
        The <strong>CTFL (Certified Tester Foundation Level)</strong>{" "}
        Certification is a globally recognized entry-level certification
        for software testers. It provides foundational knowledge in
        software testing, covering basic principles and
        tools necessary for starting a career in testing.
      </p>

      
    </div>
  </div>
</div>
<div className="istqb-section ">
  <div className="istqb-content">
    <img src={csqa} alt="ISTQB Certification" className="istqb-image" />
    <div className="istqb-text">
      <h2 className="istqb-heading">CSQA Certification</h2>
      <p className="istqb-paragraph">
        The <strong>CSQA (Certified Software Quality Analyst)</strong>{" "}
        Certification is a globally recognized credential for software
        quality professionals. It focuses on assessing and improving
        quality assurance processes, providing a structured approach to
        quality management and analysis.
      </p>
     
    </div>
  </div>
</div>
<div className="istqb-section ">
  <div className="istqb-content">
    <img src={cqa} alt="ISTQB Certification" className="istqb-image" />
    <div className="istqb-text">
      <h2 className="istqb-heading">CQA Certification</h2>

      <p className="istqb-paragraph">
        The <strong> CQA (Certified Quality Analyst)</strong>{" "}
        Certification is a highly respected credential for professionals
        in the field of quality assurance. This certification focuses on
        advanced principles and practices of quality management and
        testing, ensuring the ability to implement effective quality
        control systems and processes.
      </p>
     
    </div>
  </div>
</div>
<div className="istqb-section ">
  <div className="istqb-content">
    <img
      src={agile}
      alt="ISTQB Certification"
      className="istqb-image"
    />
    <div className="istqb-text">
      <h2 className="istqb-heading">Agile Certification</h2>
      <p className="istqb-paragraph">
        The <strong>Agile Certification</strong> is a credential that
        demonstrates expertise in Agile methodologies and practices. It
        is designed for professionals looking to enhance their skills in
        Agile frameworks like Scrum, Kanban, and Lean, and to apply
        Agile principles to improve project management and delivery.
      </p>


    </div>
  </div>
</div>
<div className="istqb-section ">
  <div className="istqb-content">
    <img
      src={automationtesting}
      alt="ISTQB Certification"
      className="istqb-image"
    />
    <div className="istqb-text">
      <h2 className="istqb-heading">
        Automation Testing Certification
      </h2>
      <p className="istqb-paragraph">
        The <strong>Automation Testing Certification</strong> is
        designed for professionals who want to demonstrate their
        expertise in automated testing tools and techniques. This
        certification covers the use of automated testing frameworks,
        scripting languages, and strategies for improving software
        testing efficiency and effectiveness.
      </p>
    </div>
  </div>
</div>


</div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen">
      {/* Course details section */}
      <div className="w-full bg-orange-100 py-6 md:py-8">
        <div className="max-w-6xl mx-auto flex flex-col md:flex-row items-center justify-between px-6">
          <div>
            <div className="flex items-center justify-center md:justify-start mb-4">
              {[...Array(rating)].map((_, index) => (
                <FaStar key={index} className="text-yellow-500 w-6 h-6" />
              ))}
            </div>
            <span className="text-lg font-semibold text-gray-700">
              Highly Rated
            </span>
            <h1 className="text-4xl font-bold">{course.title}</h1>
          </div>
          <img
            src={course.image}
            alt={course.title}
            className="w-auto h-64 object-contain"
          />
        </div>
      </div>

      {/* Course content */}
      {CourseDetails(course)}
    </div>
  );
};

export default CourseDetails;
