import React, { useEffect } from "react";

import TrainingCard from './TrainingCard';
import CourseImg from '../Assests/corporateTraining/course.png';
import course from '../Assests/course.png'
import FullStackImg from '../Assests/corporateTraining/1.png';
import SeleniumImg from '../Assests/corporateTraining/2.png';
import AWSImg from '../Assests/corporateTraining/3.png';
import Testimonial from '../CourseCards/CourseList/Testimonial';

const courses = [
  {
    id: 1,
    mode: 'Live Training',
    description: '20000+ Successful Learners',
    rating: 5, // Assuming you have a rating component
    corporates: '100+ Corporates',
    mentors: '50+ Mentors',
    imgSrc: FullStackImg,
    gradientClass: 'bg-gradient-to-r from-purple-700 to-white'
  },
  {
    id: 2,
    mode: 'Recorded Training',
    description: '5000+ Successful Learners',
    rating: 5, // Assuming you have a rating component
    corporates: '25+ Corporates',
    mentors: '50+ Mentors',
    sessions: '40+ Sessions',
    imgSrc: SeleniumImg,
    gradientClass: 'bg-gradient-to-r from-teal-700 to-white'
  },
  {
    id: 3,
    mode: 'Blended Training',
    description: '10000+ Successful Learners',
    rating: 5, // Assuming you have a rating component
    corporates: '50+ Corporates',
    mentors: '50+ Mentors',
    imgSrc: AWSImg,
    gradientClass: 'bg-gradient-to-r from-green-700 to-white'
  }
];

const CorporateTraining = () =>  {
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page
  }, []);

  return (
    <div className="min-h-screen bg-gray-100">
      {/* Full-width background section */}
      <div className="w-full bg-orange-100 py-6 md:py-8">
        <div className="max-w-7xl mx-auto flex flex-col md:flex-row items-center justify-between px-6">
          <h1 className="text-4xl md:text-3xl font-bold text-center md:text-left mb-4 md:mb-0 uppercase">Corporate Training</h1>
          <img src={course} alt="Courses" className="w-48 h-auto md:w-72 object-contain" />
        </div>
      </div>
  
      <div className="max-w-7xl mx-auto p-6 md:p-12">
        {/* Display message if no courses available */}
        {courses.length === 0 ? (
          <div className="text-center py-6">
            <h2 className="text-xl font-semibold text-gray-700">No courses available at the moment.</h2>
            <p className="text-gray-500 mt-2">Please check back later.</p>
          </div>
        ) : (
          <div className="flex flex-wrap gap-6 max-w-screen-2xl mx-auto">
            {courses.map(course => (
              <TrainingCard 
                key={course.id} 
                course={course} 
                className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4" // Adjust card width based on screen size
              />
            ))}
          </div>
        )}
      </div>
      
      <div className="bg-gray-800 py-6">
        <Testimonial />
      </div>
    </div>

  );
}
export default CorporateTraining;
