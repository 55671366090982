import React, { useEffect } from "react";
import HeroPage from "../HeroPage/HeroPage";
import Carousel from "../Carousel/Carousel";
import Factors from "../Factors/Factors";
import Career from "../Career/Career";
import Testimonial from "../CourseCards/CourseList/Testimonial";
import Ready from "../Ready/Ready";
import Courses from "../CourseCards/Courses";

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page
  }, []);
  return (
    <div>
      <HeroPage />
      <Carousel />
      <Courses />
      <Factors />
      <div className="mt-5">
      <Career />
      </div>
      <div className="bg-gray-800 py-6 mb-14">
        <Testimonial />
      </div>
      <Ready />
    </div>
  );
};

export default Home;
