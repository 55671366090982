import React, { useState } from "react";

const Filter = ({ selectedCategory, onSelectCategory, setSearchInput }) => {
  const categories = ["All", "Testing", "Development", "Others"];

  return (
    <div className="flex flex-wrap justify-center gap-2 py-6">
      {categories.map((category) => (
        <button
          key={category}
          onClick={() => {
            onSelectCategory(category); // Handle the category change
            setSearchInput(""); // Clear the search input passed from the parent component
          }}
          className={`px-4 py-2 rounded-full text-sm md:text-base transition-colors duration-300 ${
            selectedCategory === category
              ? "bg-[#273f52] text-white font-semibold"
              : "bg-gray-200 text-gray-700 font-medium"
          }`}
        >
          {category}
        </button>
      ))}
    </div>
  );
};

export default Filter;
