import React from "react";
import { IoMailSharp } from "react-icons/io5";
import { IoLogoWhatsapp } from "react-icons/io";
import Heroimage1 from "../Assests/Heroimage1.jpg";
import icon1 from "../Assests/icons/icon1.png";
import icon3 from "../Assests/icons/icon3.png";
import "./HeroPage.css";

const HeroPage = () => {
  return (
    <div className="relative overflow-hidden">
      <div class="animateme">
  <ul class="bg-bubbles">
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
   
  </ul>
</div>

      <div className="flex flex-col md:flex-row items-center justify-evenly p-6 md:p-0">
        <img
          className="w-full md:w-1/2 md:hidden lg:w-1/3 mt-6 md:mt-0"
          src={Heroimage1}
          alt="Career Upgrade"
        />
        <div className="flex flex-col w-full md:w-1/2 space-y-6 md:space-y-8 p-6 md:p-8 lg:p-12">
          <h2 className="text-3xl md:text-4xl lg:text-5xl font-bold fontsize-64px text-gray-00 build">
            Build your skills & Upgrade your Career.
          </h2>
          <p className="text-gray-700 text-base md:text-lg lg:text-xl">
            We are here to help you maximize every opportunity in your career
            roadmap.
          </p>

          <div className="flex flex-wrap gap-4">
            <div className="bg-white p-4 rounded-lg shadow-lg flex flex-col items-center text-center w-full sm:w-1/3 hover:shadow-2xl transition-transform transform hover:scale-105">
              <img src={icon3} alt="For Students" className="w-12 h-12 mb-4" />
              <span className="text-gray-500 text-sm">For</span>
              <p className="text-gray-900 font-semibold text-lg">Students</p>
            </div>
            <div className="bg-white p-4 rounded-lg shadow-lg flex flex-col items-center text-center w-full sm:w-1/3 hover:shadow-2xl transition-transform transform hover:scale-105">
              <img
                src={icon1}
                alt="For IT & Non IT Employees"
                className="w-12 h-12 mb-4"
              />
              <span className="text-gray-500 text-sm">For IT & Non-IT</span>
              <p className="text-gray-900 font-semibold text-lg">Employees</p>
            </div>
          </div>

          <div className="flex flex-col space-y-4">
            <div className="text-gray-600 font-semibold uppercase text-md">
              YOU CAN REACH US AT
            </div>
            <div className="flex flex-col md:flex-row md:space-x-6 space-y-2 md:space-y-0">
              <div className="flex items-center text-gray-800 text-lg font-medium">
                <IoMailSharp className="text-orange-500 mr-2" />{" "}
                hello@pentipsolutions.com
              </div>
              <div className="flex items-center text-gray-800 text-lg font-medium">
              <a href="https://wa.me/919014994800?text" className="flex">
              <p className="text-orange-500 mr-2 pt-2" >
                <IoLogoWhatsapp />
              </p>
              <p className="pt-1">+91 90 1499 4800</p>
            </a>
              </div>
            </div>
          </div>

          <div className="mt-6">
            <a
              href="/contactus"
              className="inline-block px-6 py-3 bg-orange-500 text-white font-semibold rounded-lg shadow-lg hover:bg-orange-600 transition-colors text-center"
            >
              Get in Touch
            </a>
          </div>
        </div>
        <img
          className="w-full hidden md:block md:w-1/2 lg:w-1/2 max-w-[33%] mt-6 md:mt-0"
          src={Heroimage1}
          alt="Career Upgrade"
        />
      </div>

      {/* Animated Background */}
      {/* <div className="ripple-background">
        <div className="circle xxlarge shade1"></div>
        <div className="circle xlarge shade2"></div>
        <div className="circle large shade3"></div>
        <div className="circle medium shade4"></div>
        <div className="circle small shade5"></div>
      </div> */}
    </div>
  );
};

export default HeroPage;
