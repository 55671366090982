import React, { useState } from "react";
import { MdArrowBack, MdArrowForward } from "react-icons/md"; // Arrow icons
import { FaStar } from "react-icons/fa"; // Star icon

import ravindhra from '../../Assests/Testimonals/ravindhra.jpg'
import Bhaskar from '../../Assests/Testimonals/Bhaskar.jpg'
import sravani from '../../Assests/Testimonals/sravani.jpg'
import BhagyaSri from '../../Assests/Testimonals/BhagyaSri.jpg'
import santhosh from '../../Assests/Testimonals/santhosh.jpg'
import Ashok from '../../Assests/Testimonals/Ashok.jpg'

const testimonials = [
  {
    name: "Santosh",
    position: "Playwright JS",
    text: "Learning Playwright JS at Pentip was simple and engaging. The step-by-step process and examples made me proficient quickly and now I work in Ontario, Canada.",
    profileImg: santhosh,
  },
  {
    name: "Bhagya Sri",
    position: "DevOps",
    text: "The DevOps course at Pentip exceeded my expectations. I learned about CI/CD pipelines and cloud integration in a real-world context and got placed in Accenture Hyderabad, India.",
    profileImg: BhagyaSri,
  },
  {
    name: "Bhaskar",
    position: "Selenium Java",
    text: "Pentip's Selenium Java course provided real-time projects and interview prep sessions that boosted my confidence. I highly recommend it! I am now working in London, UK.",
    profileImg: Bhaskar,
  },
  {
    name: "Sravani",
    position: "Playwright Python",
    text: "The Playwright Python program was detailed and practical, covering all essential topics. I received multiple job offers soon and got placed at IBM Bangalore, India.",
    profileImg: sravani,
  },
  {
    name: "Ashok",
    position: "SAP",
    text: "Pentip's SAP was excellent. The instructors were patient, and the exercises were practical. This course boosted my confidence and landed me a job in Dubai.",
    profileImg: Ashok,
  },
  {
    name: "Ravindra",
    position: "Testing Bootcamp",
    text: "The Testing Bootcamp at Pentip was a game-changer. Hands-on projects and well-structured content made automation easy and I am currently working in HCL Hyderabad, India.",
    profileImg: ravindhra,
  },
];

const Testimonial = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const itemsPerPage = 3;

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      Math.min(prevIndex + 1, testimonials.length - itemsPerPage)
    );
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  const hasNext = currentIndex < testimonials.length - itemsPerPage;
  const hasPrev = currentIndex > 0;

  return (
    <div className="max-w-7xl mx-auto p-6">
      <div className="text-center mb-12">
        <h2 className="text-2xl text-white font-bold mb-4">Testimonials</h2>
        <p className="text-3xl md:text-4xl text-white font-bold mb-6">
          What our learners say about us
        </p>
      </div>
      <div className="relative">
        {/* Mobile view: Hide navigation buttons */}
        <div className="hidden md:flex items-center mb-6">
          <MdArrowBack
            size={32}
            className={`cursor-pointer text-white ${!hasPrev ? "opacity-50 cursor-not-allowed" : ""}`}
            onClick={hasPrev ? handlePrev : null}
          />
          <div className="flex flex-nowrap overflow-hidden w-full">
            <div
              className="flex transition-transform duration-300"
              style={{
                transform: `translateX(-${currentIndex * (100 / itemsPerPage)}%)`,
              }}
            >
              {testimonials.map((testimonial, index) => (
                <div key={index} className="w-[calc(100%/3)] p-6 flex-shrink-0 flex flex-col">
                  <div className="bg-white p-6 rounded-lg shadow-lg flex-grow">
                    <div className="flex items-center mb-4">
                      <img
                        src={testimonial.profileImg}
                        alt={testimonial.name}
                        className="w-16 h-16 rounded-full mr-4"
                      />
                      <div>
                        <h3 className="text-xl font-semibold">
                          {testimonial.name}
                        </h3>
                        <p className="text-sm text-gray-600">
                          {testimonial.position}
                        </p>
                      </div>
                    </div>
                    <div className="mb-4">
                      <div className="flex">
                        {[...Array(5)].map((_, i) => (
                          <FaStar key={i} className="w-5 h-5 text-yellow-500" />
                        ))}
                      </div>
                    </div>
                    <p className="text-gray-700 text-sm">{testimonial.text}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <MdArrowForward
            size={32}
            className={`cursor-pointer text-white ${!hasNext ? "opacity-50 cursor-not-allowed" : ""}`}
            onClick={hasNext ? handleNext : null}
          />
        </div>

        {/* Mobile view: Horizontal scroll */}
        <div className="md:hidden overflow-x-auto">
          <div className="flex w-full space-x-4">
            {testimonials.map((testimonial, index) => (
              <div key={index} className="w-full p-4 flex-shrink-0 flex flex-col">
                <div className="bg-white p-6 rounded-lg shadow-lg flex-grow">
                  <div className="flex items-center mb-4">
                    <img
                      src={testimonial.profileImg}
                      alt={testimonial.name}
                      className="w-16 h-16 rounded-full mr-4"
                    />
                    <div>
                      <h3 className="text-xl font-semibold">
                        {testimonial.name}
                      </h3>
                      <p className="text-sm text-gray-600">
                        {testimonial.position}
                      </p>
                    </div>
                  </div>
                  <div className="mb-4">
                    <div className="flex">
                      {[...Array(5)].map((_, i) => (
                        <FaStar key={i} className="w-5 h-5 text-yellow-500" />
                      ))}
                    </div>
                  </div>
                  <p className="text-gray-700 text-sm">{testimonial.text}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonial;
