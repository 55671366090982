import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Carousel.css";
import Squares from "../Assests/squares.png";
import Amazon from "../Assests/Carousel/Amazon.png";
import Google from "../Assests/Carousel/Google.png";
import Microsoft from "../Assests/Carousel/Microsoft.png";
import Phonepe from "../Assests/Carousel/Phonepe.png";
import HCL from "../Assests/Carousel/HCL.png";
import Cognizant from "../Assests/Cognizant.png";
import TechMahindra from "../Assests/Carousel/TechMahindra.png";

import TCS from "../Assests/TCS.png";
const Carousel = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
          arrows: true, // Show arrows for tablet
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          arrows: false, // Remove arrows for mobile
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false, // Remove arrows for mobile
        },
      },
    ],
  };

  return (
    <div className="start-course-container">
      <div className="start-course">
        <div className="start-course-subcontainer">
          <img src={Squares} />
          <a href="/all-courses">
            <p>START COURSES</p>
          </a>
        </div>
      </div>
      <div className="start-learnings">
        <p>Our Insights and Achievements</p>
      </div>
      <div className="carousel-container">
        <Slider {...settings}>
          <div className="carousel-image">
            <img src={Amazon} alt="Amazon" />
          </div>
          <div className="carousel-image">
            <img src={Google} alt="Google" />
          </div>
          <div className="carousel-image">
            <img src={Microsoft} alt="Microsoft" />
          </div>
          <div className="carousel-image">
            <img src={Cognizant} alt="Cognizant" />
          </div>
          <div className="carousel-image">
            <img src={TCS} alt="tcs" />
          </div>
          <div className="carousel-image">
            <img src={HCL} alt="hcl" />
          </div>
          <div className="carousel-image">
            <img src={TechMahindra} alt="hcl" />
          </div>
        
        </Slider>
      </div>
    </div>
  );
};

export default Carousel;
