import React, { useState, useEffect } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import Filter from "./Filter";
import CourseCard from "./CourseCard";
import courseImage from "../../Assests/course.png";
import Testimonial from "./Testimonial";
import { courses } from "./CourseItem";
import NoCoursefound from "../../Assests/courses/NoCoursefound.png";
import Navbar from "../../Navbar/Navbar";

const CourseList = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [filteredCourses, setFilteredCourses] = useState(courses);
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchInput, setSearchInput] = useState(
    searchParams.get("search") || ""
  ); // Manage input state
  const location = useLocation();

  // Handle category change
  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
    
    // Clear the search input and search params when the category changes
    setSearchInput(""); // If you want to clear the input box text
    setSearchTerm("");   // If you are using this as the search term
    setSearchParams({ search: "" }); // Reset search params in the URL
  };

  // Function to clear the search input
  const clearSearchInput = () => {
    setSearchInput(""); // Clear the input field in Navbar
    setSearchParams({ search: "" }); // Optionally clear search params
  };

  // Handle search form submit
  const handleSearchSubmit = (e) => {
    e.preventDefault();
    setSearchParams({ search: searchInput }); // Set the search query in the URL params
  };

  // When the search changes, update the URL and trigger filtering
  useEffect(() => {
    const searchQuery = searchParams.get("search") || "";
    setSearchInput(searchQuery); // Update the input field if searchParams change
  }, [searchParams]);

  useEffect(() => {
    // Filter by category first
    const filteredByCategory = selectedCategory === "All" 
      ? courses 
      : courses.filter((course) => course.category === selectedCategory);

    // Further filter by search input
    const finalFilteredCourses = searchInput 
      ? filteredByCategory.filter((course) => 
          course.title?.toLowerCase().includes(searchInput.toLowerCase()))
      : filteredByCategory;

    setFilteredCourses(finalFilteredCourses);
  }, [selectedCategory, searchInput]);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);

  return (
    <div className="min-h-screen bg-gray-100">
      <div className="w-full bg-orange-100 py-6 md:py-8">
        <div className="max-w-7xl mx-auto flex flex-col md:flex-row items-center justify-between px-6">
          <h1 className="text-4xl md:text-3xl font-bold text-center md:text-left mb-4 md:mb-0 text-black">
            ALL COURSES
          </h1>
          <img
            src={courseImage}
            alt="Courses"
            className="w-48 h-auto md:w-52 object-contain"
          />
        </div>
      </div>
      <div className="max-w-7xl mx-auto p-6">
        <Filter
          selectedCategory={selectedCategory}
          onSelectCategory={handleCategoryChange} // Correct handler for category change
          searchInput={searchInput} // Passing search input state
          setSearchInput={setSearchInput} // Set input state correctly (removed duplicate)
          onClearSearch={clearSearchInput} // Pass a function to clear input
        />

        {filteredCourses.length === 0 ? (
          <div className="text-center py-6">
            <h2 className="text-xl font-semibold text-gray-700">
              No courses available for the selected category.
            </h2>
            <p className="text-gray-500 mt-2">
              Try selecting a different category or check back later.
            </p>
            <img
              src={NoCoursefound}
              alt="No courses available"
              className="mx-auto w-1/2 md:w-1/4 h-auto mt-4"
            />
          </div>
        ) : (
          <div className="flex flex-wrap gap-6 justify-center md:justify-start">
            {filteredCourses.map((course) => (
              <CourseCard
                key={course.id}
                course={course}
                className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4"
              />
            ))}
          </div>
        )}
      </div>

      <div className="bg-gray-800 py-6">
        <Testimonial />
      </div>
    </div>
  );
};

export default CourseList;
