import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";

import Navbar from "./components/Navbar/Navbar";

import Footer from "./components/Footer/Footer";

import CourseList from "./components/CourseCards/CourseList/CourseList";

import Home from "./components/Home/Home";
import About from "./components/About/About";
import CourseDetails from "./components/CourseCards/CourseList/CourseDetails";
import Contact from "./components/Contact/Contact";
import CorporateTraining from "./components/CorporateTraining/CorporateTraining";
import CertificateDetails from "./components/CourseCards/CourseList/CertificateDetails";
import ScrollToTop from "./components/Home/ScrollToTop";
import WhatsAppButton from "./components/Home/WhatsAppButton";


function App() {
  const [searchInput, setSearchInput] = useState("");
  return (
    <div className="App">
  
      <Router>
   <ScrollToTop/>
   <Navbar searchInput={searchInput} setSearchInput={setSearchInput} />
        

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/all-courses" element={<CourseList searchInput={searchInput} setSearchInput={setSearchInput} />} />
          <Route path="/course/:id" element={<CourseDetails />} />
          <Route path="/contactus" element={<Contact />} />
          <Route path="/corporatetraining" element={<CorporateTraining />} />
          <Route path="/certificate-details" element={<CertificateDetails/>} />
        </Routes>
        <Footer />
        <WhatsAppButton/>
      </Router>

  
    </div>
  );
}

export default App;
