import React from "react";
import CareerCard from "./CareerCard";
import "./Career.css";
import icon1 from "../Assests/icons/icon1.png";
import icon2 from "../Assests/icons/icon2.png";
import icon3 from "../Assests/icons/icon3.png";
import icon4 from "../Assests/icons/icon4.png";
import squares from "../Assests/squares.png";

function Career() {
  return (
    <div className="career-main-container">
      <div className="card-section">
        <div className="card-container-1">
          <CareerCard
            icon={icon1}
            title="IT Employed"
            description="Advance your skills to close talent gaps and switch careers."
          />
          <CareerCard
            icon={icon3}
            title="Fresh Graduates"
            description="Get your hands on real-time projects and start your IT career."
          />
        </div>
        <div className="card-container-2">
          <CareerCard
            icon={icon2}
            title="Return from Work Break"
            description="Re-skill yourself to stay ahead of the competition and in trend."
          />
          <CareerCard
            icon={icon4}
            title="Non IT to IT"
            description="Learn, Get Certified with new demanding skills and Switch.
How does it help?"
          />
        </div>
      </div>
      <div className="hero-section">
        <p>How it helps ?</p>
        <h1>Career Path Guidance</h1>
        <p className="m-0 mt-0 mb-4">
          At the beginning of your career, it's crucial to prioritize skills
          over salary. The right skills will not only open doors but also set
          the foundation for long-term success. At Pentip, we focus on helping
          you acquire in-demand skills through hands-on projects and real-world
          scenarios. Our courses are designed to immerse you in practical
          learning, allowing you to visualize concepts and apply them
          meaningfully.
        </p>
        <p>
          We believe that a growth mindset is essential for continuous
          development. By fostering this mindset, we empower you to become a
          problem-solver capable of tackling any challenge. Our goal is not just
          to prepare you for a job but to help you build a fulfilling career.
          Whether you’re looking to break into a new field or sharpen your
          existing skills, Pentip is committed to guiding you every step of the
          way.
        </p>
      </div>
    </div>
  );
}

export default Career;
