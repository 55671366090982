import React from "react";
import { Link } from 'react-router-dom';
const CourseCard = ({ course }) => (
  <Link to={`/course/${course.id}`} className="flex justify-center mb-4">
    <div className="bg-white shadow-md rounded-2xl overflow-hidden w-72 hover:shadow-2xl transition-all cursor-pointer">
      {/* Image Section */}
      <div className="relative h-auto overflow-hidden">
        <img
          src={course.image}
          alt={course.title}
          className="object-contain w-full h-full"
        />
      </div>
      <div className="p-4">
        <h3 className="text-lg text-green-800 font-semibold uppercase">
          {course.mode}
        </h3>
        <p className="text-gray-600">{course.description}</p>
        <div className="flex justify-between items-center">
          <div className="flex items-center">
            {[...Array(course.rating)].map((_, i) => (
              <span key={i} className="text-yellow-500 text-2xl">
                ★
              </span>
            ))}
          </div>
          <p className="text-2xl text-black flex items-center">
            {course.hours}
            <span className="text-xs align-top ml-1">days</span>
          </p>
        </div>
        {/* <p className="text-gray-600 text-sm">{course.earnings}</p> */}
      </div>
    </div>
  </Link>
);
export default CourseCard;










