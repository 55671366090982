import React from "react";
import "./Ready.css";

const Ready = () => {
  return (
    <div className="ready-main-container">
    
      <div className="ready-container">
        <div className="ready-content">
          <p>Ready to get Started?</p>
          <span>Sign up,get early bird offer</span>
        </div>
        <div className="ready-link">
          <a href="/all-courses">START COURSE</a>
        </div>
      </div>
    </div>
  );
};

export default Ready;
