import React from 'react'
import outlet from '../Assests/outlet.png'
import './Factors.css'
import pentipfactor from '../Assests/pentipfactor.png'
const Factors = () => {
  return (
    <div className='outlet-container'>
        <img src={pentipfactor}/>

    </div>
  )
}

export default Factors