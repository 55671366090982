import React from 'react';

const TrainingCard = ({ course }) => (
    <div className="bg-white shadow-md rounded-2xl overflow-hidden w-full sm:w-[44%] md:w-[45%] lg:w-[30%] hover:shadow-2xl transition-all cursor-pointer">
        {/* Header section with a solid background color */}
        <div className="bg-blue-500 text-white mb-4 h-72 flex items-center justify-center">
            <img src={course.imgSrc} alt={course.title} className="w-full h-full object-cover" />
        </div>
        {/* Body section */}
        <div className='p-4'>
            <h3 className="text-lg text-green-800 font-semibold uppercase mb-2">{course.mode}</h3>
            <p className="text-gray-600 mb-4">{course.description}</p>
            <div className='flex justify-between mb-4'>
                <div>
                    <div className="flex items-center">
                        {[...Array(course.rating)].map((_, i) => (
                            <span key={i} className="text-yellow-500 text-2xl">★</span>
                        ))}
                    </div>
                    <p className="text-gray-600 text-sm">{course.corporates}</p>
                </div> 
                <p className="text-gray-600 text-sm">{course.mentors}</p>
            </div>
        </div>
    </div>
);

export default TrainingCard;