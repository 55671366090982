import React from "react";
import istqb from "../../Assests/courses/ISTQB.webp";
import "./CertificationDetails.css";
import cste from "../../Assests/courses/Certifications/cste.webp";
import ctfl from "../../Assests/courses/Certifications/ctfl.webp";
import csqa from "../../Assests/courses/Certifications/csqa.webp";
import cqa from "../../Assests/courses/Certifications/cqa.webp";
import agile from "../../Assests/courses/Certifications/agile.webp";
import automationtesting from "../../Assests/courses/Certifications/automationtesting.webp";
import certificationcourses from "../../Assests/courses/certificationcourses.webp";
import { FaStar } from "react-icons/fa";

const CertificateDetails = () => {
  return (
    <div className="min-h-screen">
      <div className="w-full bg-orange-100 py-6 md:py-8">
          <div className="max-w-6xl mx-auto flex flex-col md:flex-row items-center justify-between px-6">
            {/* Rating Section */}
            <div>
              <div className="flex items-center justify-center md:justify-start mb-4">
                {[...Array(4)].map((_, index) => (
                  <FaStar key={index} className="text-yellow-500 w-6 h-6" />
                ))}
              </div>
              <span className="text-lg font-semibold text-gray-700">
                Highly Rated
              </span>
              <h1 className="text-4xl font-bold">Certificate Courses</h1>
            </div>
          <img
            src={certificationcourses}
            alt="certificationcourses"
            className="w-auto h-64 object-contain"
          />
        </div>
      </div>

      <div className="certificate-cards-container">

        <div className="istqb-section ">
          <div className="istqb-content">
            <img
              src={istqb}
              alt="ISTQB Certification"
              className="istqb-image "
            />
            <div className="istqb-text">
              <h2 className="istqb-heading">ISTQB Certification</h2>
              <p className="istqb-paragraph">
                The{" "}
                <strong>
                  ISTQB (International Software Testing Qualifications Board)
                </strong>{" "}
                Certification is a globally recognized certification for
                software testers. It aims to standardize the qualifications and
                knowledge in the software testing field, providing a structured
                path for career growth.
              </p>
            </div>
          </div>
        </div>
        <div className="istqb-section ">
          <div className="istqb-content">
            <img src={cste} alt="ISTQB Certification" className="istqb-image" />
            <div className="istqb-text">
              <h2 className="istqb-heading">CSTE Certification</h2>
              <p className="istqb-paragraph">
                The<strong>CSTE (Certified Software Test Engineer)</strong>{" "}
                Certification is a recognized credential for software testers.
                It aims to validate and standardize knowledge and expertise in
                the field of software testing, providing a structured path for
                career advancement.
              </p>
             
            </div>
          </div>
        </div>
        <div className="istqb-section ">
          <div className="istqb-content">
            <img src={ctfl} alt="ISTQB Certification" className="istqb-image" />
            <div className="istqb-text">
              <h2 className="istqb-heading">CTFL Certification</h2>
              <p className="istqb-paragraph">
                The <strong>CTFL (Certified Tester Foundation Level)</strong>{" "}
                Certification is a globally recognized entry-level certification
                for software testers. It provides foundational knowledge in
                software testing, covering basic principles, methodologies, and
                tools necessary for starting a career in testing.
              </p>

              
            </div>
          </div>
        </div>
        <div className="istqb-section ">
          <div className="istqb-content">
            <img src={csqa} alt="ISTQB Certification" className="istqb-image" />
            <div className="istqb-text">
              <h2 className="istqb-heading">CSQA Certification</h2>
              <p className="istqb-paragraph">
                The <strong>CSQA (Certified Software Quality Analyst)</strong>{" "}
                Certification is a globally recognized credential for software
                quality professionals. It focuses on assessing and improving
                quality assurance processes, providing a structured approach to
                quality management and analysis.
              </p>
             
            </div>
          </div>
        </div>
        <div className="istqb-section ">
          <div className="istqb-content">
            <img src={cqa} alt="ISTQB Certification" className="istqb-image" />
            <div className="istqb-text">
              <h2 className="istqb-heading">CQA Certification</h2>

              <p className="istqb-paragraph">
                The <strong> CQA (Certified Quality Analyst)</strong>{" "}
                Certification is a highly respected credential for professionals
                in the field of quality assurance. This certification focuses on
                advanced principles and practices of quality management and
                testing, ensuring the ability to implement effective quality
                control systems and processes.
              </p>
             
            </div>
          </div>
        </div>
        <div className="istqb-section ">
          <div className="istqb-content">
            <img
              src={agile}
              alt="ISTQB Certification"
              className="istqb-image"
            />
            <div className="istqb-text">
              <h2 className="istqb-heading">Agile Certification</h2>
              <p className="istqb-paragraph">
                The <strong>Agile Certification</strong> is a credential that
                demonstrates expertise in Agile methodologies and practices. It
                is designed for professionals looking to enhance their skills in
                Agile frameworks like Scrum, Kanban, and Lean, and to apply
                Agile principles to improve project management and delivery.
              </p>


            </div>
          </div>
        </div>
        <div className="istqb-section ">
          <div className="istqb-content">
            <img
              src={automationtesting}
              alt="ISTQB Certification"
              className="istqb-image"
            />
            <div className="istqb-text">
              <h2 className="istqb-heading">
                Automation Testing Certification
              </h2>
              <p className="istqb-paragraph">
                The<strong>Automation Testing Certification</strong> is
                designed for professionals who want to demonstrate their
                expertise in automated testing tools and techniques. This
                certification covers the use of automated testing frameworks,
                scripting languages, and strategies for improving software
                testing efficiency and effectiveness.
              </p>
            </div>
          </div>
        </div>

        
      </div>





      
    </div>
  );
};

export default CertificateDetails;
