import React from "react";
import { IoLogoWhatsapp } from "react-icons/io";
import { FaFacebook, FaLinkedin, FaPhoneAlt } from "react-icons/fa";
import { RiInstagramFill } from "react-icons/ri";
import { IoLogoYoutube } from "react-icons/io5";
import { BsGlobe } from "react-icons/bs";
import { FaLocationDot } from "react-icons/fa6";
import { IoMailSharp } from "react-icons/io5";
import { Link } from "react-router-dom";
import { courses } from "../CourseCards/CourseList/CourseItem";

const Footer = () => {
  const courseList = courses || [];
  
  return (
    <footer className="bg-gray-800 text-gray-200 p-8 mt-14">
      <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">

        <div className="footer-section">
          <h3 className="text-xl font-semibold border-b-2 border-gray-600 pb-2 font-roboto">
            Pentip Software Solutions
          </h3>
          <p className="mt-2">
            Offering a rigorous academic program with a history of excellence in
            testing and development.
          </p>
          <div className="contact-info mt-4">
            <div className="flex items-center mt-2">
              <FaLocationDot className="mr-2" />
              <span>
                F#16, Banjara Castle, Road No: 12, Banjara Hills, Hyderabad,
                Telangana, 500034
              </span>
            </div>
            <div className="flex items-center mt-2">
              <FaPhoneAlt className="mr-2" />
              <span>9014994800</span>
            </div>
            <div className="flex items-center mt-2">
              <IoMailSharp className="mr-2" />
              <span>hello@pentipsolutions.com</span>
            </div>
          </div>
        </div>

        <div className="footer-section">
          <h3 className="text-xl font-semibold border-b-2 border-gray-600 pb-2 font-roboto">
            Company
          </h3>
          <ul className="mt-2 space-y-1">
            <li>
              <Link to="/home" className="hover:underline">
                Home
              </Link>
            </li>
            <li>
              <Link to="/about" className="hover:underline">
                About
              </Link>
            </li>
            <li>
              <Link to="/contactus" className="hover:underline">
                Contact Us
              </Link>
            </li>
            <li>
              <Link to="/corporatetraining" className="hover:underline">
                Corporate Training
              </Link>
            </li>
            <li>
              <Link to="/all-courses" className="hover:underline">
                Courses
              </Link>
            </li>
          </ul>
        </div>

        <div className="footer-section">
          <h3 className="text-xl font-semibold border-b-2 border-gray-600 pb-2 font-roboto">
            Courses
          </h3>
          <ul className="mt-2 grid grid-cols-1 md:grid-cols-2 gap-2">
            {courseList.length > 0 ? (
              courseList.map((course) => (
                <li key={course.id} className="line-clamp-1">
                  <Link to={`/course/${course.id}`} className="hover:underline">
                    {course.title}
                  </Link>
                </li>
              ))
            ) : (
              <li>No courses available</li>
            )}
          </ul>
        </div>

        <div className="footer-section">
          <h3 className="text-xl font-semibold border-b-2 border-gray-600 pb-2 font-roboto">
            Follow Us
          </h3>
          <div className="flex flex-wrap mt-4 space-x-4">
            <a
              href="https://www.facebook.com/share/kU8hAR4dsm7G3Fuy/?mibextid=qi2Omg"
              className="hover:text-orange-500"
            >
              <FaFacebook />
            </a>
            <a
              href="https://www.instagram.com/pentipsolutions?igsh=MWRvcG5qYjY4d3FuMg=="
              className="hover:text-orange-500"
            >
              <RiInstagramFill />
            </a>
            <a
              href="https://www.youtube.com/@pentipsolutions"
              className="hover:text-orange-500"
            >
              <IoLogoYoutube />
            </a>
            <a
              href="https://wa.me/919014994800"
              className="hover:text-orange-500"
            >
              <IoLogoWhatsapp />
            </a>
            {/* <a
              href="https://www.linkedin.com/company/pentip-technology-solutions-pvt%C2%A0ltd/"
              className="hover:text-orange-500"
            >
              <FaLinkedin />
            </a> */}
          </div>
        </div>
      </div>
      <div className="mt-8 text-center border-t border-gray-600 pt-4">
        <p>
          2024 © All rights reserved by
          <a
            href="https://www.oakyaitservices.com/"
            className="font-semibold hover:underline"
          >
            {" "}
            OAKYA IT SERVICES PVT LTD.
          </a>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
