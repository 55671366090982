// src/pages/TrendingCourses.js
import React from "react";
import CourseCard from "./CoursesCard";
import "./Courses.css"; // Import page-specific styles
import { Link } from 'react-router-dom';
import testComplete from '../Assests/testComplete.png'
import sap from '../Assests/courses/SAP.webp'
import playwrightPython from '../Assests/courses/Playwrightpython.webp'
import SeleniumPython from '../Assests/courses/SeleniumPython.webp'
import { FaArrowRight } from "react-icons/fa6";
import OrbitProgress from './OrbitProgress'

const courses = [
  {
    id:1,
   
    title: "Selenium Python",
    category: "Testing",
    duration: 60,
    image: SeleniumPython,
  
  },
  {
    id:2,
    title: "Playwright Python",
    category: "Testing",
    duration: 60,
    image: playwrightPython,

  },
  {
    id:3,
    title: "TestComplete",
    category: "Testing",
    duration: 60,
    image: testComplete,

  },
  {
    id:4,
    title: "SAP",
    category: "Application Development & Automation",
    duration: 90,
    image:sap,
   
  },
];

const Courses = () => {
  
  return (
    <div className="trending-courses-page">
      <div className="courses-header">
        <h1 className="title">In-Demand Training</h1>
        <Link to="/all-courses">View all <p className="mt-1"><FaArrowRight /></p></Link>
      </div>
      <OrbitProgress variant="spokes" color="#ffb400" size="medium" text="" textColor="" />
      <div className="course-grid">
        {courses.map((course, index) => (
          <CourseCard key={index} course={course} />
        ))}
      </div>
    </div>
  );
};

export default Courses;
