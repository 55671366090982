import React from 'react';
import './CareerCard.css'
function CareerCard({ icon, title, description }) {
  return (
    <div className="card">
      <div className='image-container'><img src={icon} alt="Icon" /></div>
      
      <h3>{title}</h3>
      <p>{description}</p>
    </div>
  );
}

export default CareerCard;
