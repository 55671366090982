import React, { useState, useEffect } from "react";
import { FaBars, FaTimes, FaSearch } from "react-icons/fa";
import Pentip7 from "../Assests/Pentip7.png";
import { Link, useNavigate } from "react-router-dom";
import Filter from "../CourseCards/CourseList/Filter";

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [scrolled, setScrolled] = useState(false);
  const navigate = useNavigate();

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();

    if (searchTerm.trim()) {
      navigate(`/all-courses?search=${searchTerm}`);
        handleCategoryClick();
  
    }
    // console.log("Searching for:", searchTerm);
  };

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 0);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleCategoryClick = () => {
    setSearchTerm(""); // Clear search term when navigating to another category
  };





  return (
    <div
      className={`${scrolled ? "shadow-md" : ""} bg-white sticky top-0 z-50`}
    >
      <nav className="flex justify-between items-center px-4 md:px-8 py-4">
        <a href="/home">
          <img src={Pentip7} alt="Logo" className="w-36 md:w-24 lg:w-32" />
        </a>

        <ul
          className={`absolute md:static text-[12px] lg:text-base top-full left-0 right-0 font-roboto bg-white hidden md:flex-row items-center md:flex`}
        >
          <li>
            <Link
              to="/home"
              onClick={() => {
                toggleMenu();
                handleCategoryClick();
              }}
              className="text-black font-bold mx-2 lg:mx-3"
            >
              HOME
            </Link>
          </li>
          <li>
            <Link
              to="/about"
              onClick={() => {
                toggleMenu();
                handleCategoryClick();
              }}
              className="text-black font-bold mx-2 lg:mx-3"
            >
              ABOUT
            </Link>
          </li>
          <li>
            <Link
              to="/all-courses"
              onClick={() => {
                toggleMenu();
                handleCategoryClick();
              }}
              className="text-black font-bold mx-2 lg:mx-3"
            >
              COURSES
            </Link>
          </li>
          <li>
            <Link
              to="/corporatetraining"
              onClick={() => {
                toggleMenu();
                handleCategoryClick();
              }}
              className="text-black font-bold mx-2 lg:mx-3"
            >
              CORPORATE TRAINING
            </Link>
          </li>
          <li>
            <Link
              to="/contactus"
              onClick={() => {
                toggleMenu();
                handleCategoryClick();
              }}
              className="text-black font-bold mx-2 lg:mx-3"
            >
              CONTACT US
            </Link>
          </li>
        </ul>

        {/* Full-screen menu */}
        <div
          className={`fixed inset-0 bg-black bg-opacity-50 transition-opacity duration-300 ${
            menuOpen ? "opacity-100" : "opacity-0 pointer-events-none"
          } md:hidden font-roboto`}
        >
          <div
            className={`fixed top-0 right-0 h-full w-3/4 bg-white border-l border-gray-300 p-4 px-6 rounded-l-lg shadow-lg transform transition-transform duration-300 ${
              menuOpen ? "translate-x-0" : "translate-x-full"
            }`}
          >
            <div className="flex justify-between items-center">
              <img src={Pentip7} alt="Logo" className="w-36" />
              <FaTimes
                className="text-2xl text-black cursor-pointer"
                onClick={() => {
                  toggleMenu();
                  handleCategoryClick();
                }}
              />
            </div>
            <ul className="flex flex-col mt-12 h-full space-y-4">
              <li>
                <Link
                  to="/home"
                  onClick={() => {
                    toggleMenu();
                    handleCategoryClick();
                  }}
                  className="text-black font-semibold text-base hover:text-blue-600 transition duration-200"
                >
                  HOME
                </Link>
              </li>
              <div className="border-b"></div>
              <li>
                <Link
                  to="/about"
                  onClick={() => {
                    toggleMenu();
                    handleCategoryClick();
                  }}
                  className="text-black font-semibold text-base hover:text-blue-600 transition duration-200"
                >
                  ABOUT
                </Link>
              </li>
              <div className="border-b"></div>
              <li>
                <Link
                  to="/all-courses"
                  onClick={() => {
                    toggleMenu();
                    handleCategoryClick();
                  }}
                  className="text-black font-semibold text-base hover:text-blue-600 transition duration-200"
                >
                  COURSES
                </Link>
              </li>
              <div className="border-b"></div>
              <li>
                <Link
                  to="/corporatetraining"
                  onClick={() => {
                    toggleMenu();
                    handleCategoryClick();
                  }}
                  className="text-black font-semibold text-base hover:text-blue-600 transition duration-200"
                >
                  CORPORATE TRAINING
                </Link>
              </li>
              <div className="border-b"></div>
              <li>
                <Link
                  to="/contactus"
                  onClick={() => {
                    toggleMenu();
                    handleCategoryClick();
                  }}
                  className="text-black font-semibold text-base hover:text-blue-600 transition duration-200"
                >
                  CONTACT US
                </Link>
              </li>
              <div className="border-b"></div>
            </ul>
          </div>
        </div>

        <div className="flex items-center">
  <form onSubmit={handleSearchSubmit} className="flex">
    <input
      className="search-input px-2 py-1 border w-[136px] lg:w-44 border-gray-300 rounded-lg mr-2 text-[12px] lg:text-base"
      placeholder="Find Your Course..."
      value={searchTerm} // The search input is controlled by the state
      onChange={handleSearchChange} // Update state on change
    />
    <FaSearch
      className="cursor-pointer text-black -ml-9 mt-[7px] lg:mt-2"
      onClick={handleSearchSubmit} // Also trigger search when icon is clicked
    />
  </form>
</div>

        <div className="block md:hidden" onClick={toggleMenu}>
          <FaBars className="text-2xl text-black" />
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
